import { observer } from "mobx-react-lite";
import { FullListOfDates } from "./FullListOfDates";
import { FullListOfTags } from "./FullListOfTags";
import { FooterMainButton } from "../../common/FooterMainButton";
import { BasePage } from "../../common/BasePage";
import { useContext, useEffect, useState } from "react";
import { storeContext } from "../../../store/root-store";
import { AdminService } from "../../../services/AdminService";
import { AdminFilter } from "./AdminFilter";

const defaultButtonText = "Применить";
const getFilterButtonSettings = (
  listOfSelectedDates: any[],
  listOfSelectedTags: { id: number }[],
  defaultText: string,
  listOfGlobalTags: { id: number }[],
  listOfAdminDates: any[]
) => {
  let disabled = false;
  let message = defaultText;
  if (!listOfSelectedDates.length && !listOfAdminDates.length) {
    disabled = true;
    message = "Выберите дату";
  } else if (!listOfSelectedTags.length) {
    disabled = true;
    message = "Выберите категории";
  } else if (
    !listOfSelectedTags.filter((el) =>
      listOfGlobalTags.map((e) => e.id).includes(el.id)
    ).length
  ) {
    message = "Выберите категорию";
    disabled = true;
  } else if (listOfAdminDates.length) {
    disabled = false;
    message = "Применить";
  }
  return {
    disabled,
    message,
  };
};

export const FIlterPage = observer(() => {
  const { filterDatesGl, filterTagGl, tagsGl, adminDateFilterGl } =
    useContext(storeContext);
  const [filterButttonStatus, setFilterButtonStatus] = useState<boolean>(false);
  const [filterButtonText, setFilterButtonText] =
    useState<string>(defaultButtonText);
  useEffect(() => {
    const filterButtonSettings = getFilterButtonSettings(
      filterDatesGl.listOfSelecedDates,
      filterTagGl.listOfSelectedTags,
      defaultButtonText,
      tagsGl.listOfTags,
      Array.from(adminDateFilterGl.listOfSelectedDates)
    );
    setFilterButtonStatus(filterButtonSettings.disabled);
    setFilterButtonText(filterButtonSettings.message);
  }, [
    tagsGl.listOfTags,
    filterDatesGl.listOfSelecedDates.length,
    filterTagGl.listOfSelectedTags.length,
    Array.from(adminDateFilterGl.listOfSelectedDates).length,
  ]);
  return (
    <BasePage>
      {AdminService.getService().isAdmin() && <AdminFilter></AdminFilter>}
      <FullListOfDates></FullListOfDates>
      <FullListOfTags></FullListOfTags>
      <FooterMainButton
        disabled={filterButttonStatus}
        buttonText={filterButtonText}
        navTo="/"
      ></FooterMainButton>
    </BasePage>
  );
});
