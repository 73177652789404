import { createContext } from "react";
import { eventsStore } from "./Events";
import { messagesStore } from "./Messages";
import { settingsGl } from "./Settings";
import { singleEvent } from "./SingleEvent";
import { checkEventsGl } from "./CheckEvents";
import { tagsStore } from "./Tags";
import { filterTagsGl } from "./FilterTags";
import { filterDatesGl } from "./FilterDates";
import { adminDateFilterGl } from "./AdminDateFilter";

export const storeContext = createContext({
  eventsStore: eventsStore,
  messagesStoreGl: messagesStore,
  settingsGl: settingsGl,
  singleStoreGL: singleEvent,
  checkEventsGl: checkEventsGl,
  tagsGl: tagsStore,
  filterTagGl: filterTagsGl,
  filterDatesGl: filterDatesGl,
  adminDateFilterGl: adminDateFilterGl,
});
