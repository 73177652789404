const formatDate = (date: string) => {
  return date
    .split("-")
    .reverse()
    .filter((el, index) => index !== 2)
    .join(".");
};
export const ListOfDates = ({ list_of_dates }: { list_of_dates: string }) => {
  return (
    <div>
      <hr />
      <div className="d-flex " style={{ overflow: "scroll" }}>
        {list_of_dates.split(",").map((date) => {
          return (
            <div
              key={date}
              className="my-time-panel-button my-time-panel-button-active mr-3"
            >
              {formatDate(date)}
            </div>
          );
        })}
        {/* <span className="mr-2" style={{ fontSize: 10 + "px" }}>
        возможны неточности, проверяйти Источник
      </span> */}
      </div>
    </div>
  );
};
