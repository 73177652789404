import { makeAutoObservable } from "mobx";
import { ITag } from "../interfaces/ITag";
import ITagWithRelated from "./Tags";

class FilterTags {
  public listOfSelectedTags: ITagWithRelated[] = [];
  constructor() {
    makeAutoObservable(this);
  }
  public hasTag(tag: ITagWithRelated) {
    return this.listOfSelectedTags.filter((el) => {
      return el.id === tag.id;
    }).length;
  }
  public onSelectCategory(tag: ITagWithRelated) {
    if (filterTagsGl.hasTag(tag)) {
      filterTagsGl.listOfSelectedTags = filterTagsGl.listOfSelectedTags.filter(
        (t) => t.id !== tag.id
      );
    } else {
      filterTagsGl.listOfSelectedTags.push({ ...tag });
    }
  }
}
export const filterTagsGl = new FilterTags();
