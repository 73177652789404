import React, { useContext, useState } from "react";
import { QueryService } from "../../../services/QueryService";
import { EventPreview } from "../../../types/EventPreview";
import { AdminService } from "../../../services/AdminService";
import { observe } from "mobx";
import { observer } from "mobx-react-lite";
import { storeContext } from "../../../store/root-store";
import { ListOfDates } from "./ListOfDates";
import { MySingleEvenComp } from "../singeEvent/SingleEventPage";
import sanitize from "sanitize-html";

function replaceNewlinesWithBr(inputString: string) {
  return inputString.replace(/\n/g, "<br>");
}

interface IEvent {
  key?: any;
  event: EventPreview;
  className: string;
  onClick: (id: number) => any;
}
const getImgPath = (imgPaths: string) => {
  const paths = imgPaths.split(";");
  return paths[0];
};

enum eventDescState {
  body = "body",
  short_text = "short_text",
}
enum eventTabs {
  adminTab = "adminTab",
  userTab = "userTab",
}

export const EventComp = observer(({ event, className, onClick }: IEvent) => {
  {
    const [currentTab, setCurrentTab] = useState<eventTabs>(eventTabs.userTab);
    const { tagsGl } = useContext(storeContext);
    const [currentDescState, setDescState] = useState<eventDescState>(
      eventDescState.short_text
    );

    const changeCurrentDescState = () => {
      if (currentDescState === eventDescState.body) {
        setDescState(eventDescState.short_text);
      } else {
        setDescState(eventDescState.body);
      }
    };

    return (
      <div className={className}>
        {AdminService.getService().isAdmin() && (
          <div className="d-flex justify-content-center mb-2">
            <div
              className="me-2"
              onClick={() => {
                setCurrentTab(eventTabs.adminTab);
              }}
            >
              Админ
            </div>
            <div
              onClick={() => {
                setCurrentTab(eventTabs.userTab);
              }}
            >
              Обычный
            </div>
          </div>
        )}
        {currentTab === eventTabs.userTab && (
          <div className="card">
            <div
              className="d-flex justify-content-center mt-1 "
              style={{
                margin: "auto",
                maxHeight: 190 + "px",
                maxWidth: 50 + "%",
              }}
            >
              {event.img_path && (
                <img
                  style={{ height: "auto" }}
                  // width={100 + "%"}
                  // height={100 + "%"}
                  src={
                    "https://your-city-events.zahar-dev.ru" +
                    "/" +
                    getImgPath(event.img_path)
                  }
                  className="card-img-top"
                  alt="..."
                />
              )}
              {!event.img_path && (
                <img
                  // width={100 + "%"}
                  src={"/no-img.svg"}
                  className="card-img-top"
                  alt="..."
                />
              )}
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between mt-1 mb-1">
                <div>
                  <a
                    style={{ fontSize: 1.1 + "rem", fontWeight: "bold" }}
                    href={`https://t.me/${event.source_id
                      ?.split("_")
                      .filter((el, index, ar) => {
                        return index !== ar.length - 1;
                      })
                      .join("_")}/${event?.post_id}`}
                    target="blank"
                  >
                    Источник
                  </a>
                </div>
                {event.short_text && (
                  <>
                    {currentDescState == eventDescState.short_text && (
                      <div
                        className="text-button text-button-show-body"
                        onClick={changeCurrentDescState}
                      >
                        Показать полный текст
                      </div>
                    )}
                    {currentDescState == eventDescState.body && (
                      <div
                        className="text-button text-button-show-short"
                        onClick={changeCurrentDescState}
                      >
                        Показать короткий текст
                      </div>
                    )}
                  </>
                )}
              </div>
              {
                <p
                  className=""
                  style={{
                    // height: 25 + "vh",
                    overflow: "hidden",
                    // wordBreak: "break-all",
                  }}
                >
                  {currentDescState === eventDescState.short_text &&
                  event.short_text ? (
                    event.short_text
                  ) : (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: sanitize(replaceNewlinesWithBr(event.body), {
                            allowedTags: ["br"],
                          }),
                        }}
                        style={{ whiteSpace: "pre-line" }}
                      ></p>
                    </>
                  )}
                </p>
              }
                <ListOfDates
                  key={event.id}
                  list_of_dates={event.list_of_dates}
                />
            </div>
          </div>
        )}
        {currentTab == eventTabs.adminTab && (
          <MySingleEvenComp
            key={event.id}
            eventId={event.id}
          ></MySingleEvenComp>
        )}
      </div>
    );
  }
});
