import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { Loader } from "../../common/Loader";
import { on } from "stream";
import { observer } from "mobx-react-lite";
import { storeContext } from "../../../store/root-store";

interface ScrollComponentProps {
  children: ReactNode; // Высота контейнера

  height: string; // Высота контейнера
  onScroll: (page: number) => Promise<{ hasMore: boolean }>; // Асинхронная функция для вызова
  startPage: number;
  onFirstLoadFinish?: (firstLoad: boolean) => any;
}

const GptScroll: React.FC<ScrollComponentProps> = observer(
  ({ children, height, onScroll, startPage, onFirstLoadFinish = () => { } }) => {
    const { settingsGl } = useContext(storeContext);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [page, setPage] = useState<number>(startPage);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [firstLoad, setFirstLoad] = useState<boolean>(false);

    useEffect(() => {
      onFirstLoadFinish(firstLoad);
    }, [firstLoad]);

    useEffect(() => {}, []);

    useEffect(() => {
      let ignore = false;
      setLoading(true); // Устанавливаем состояние загрузки
      onScroll(startPage).then(() => {
        setLoading(false); // Устанавливаем состояние загрузки
        if (!ignore) {
          setPage((prevPage) => prevPage + 1);
        } // Увеличиваем номер страницы
        setFirstLoad(true);
      });
      return () => {
        ignore = true;
      };
    }, []);

    useEffect(() => {
      const handleScroll = async () => {
        if (containerRef.current) {
          const { scrollTop, clientHeight, scrollHeight } =
            containerRef.current;

          // Проверяем, достиг ли пользователь конца элемента
          if (
            scrollTop + clientHeight + 200 >= scrollHeight &&
            !loading &&
            hasMore
          ) {
            setLoading(true); // Устанавливаем состояние загрузки
            const { hasMore } = await onScroll(page); // Вызываем асинхронную функцию с номером страницы
            // alert(`Текущая - ${page}, следующая - ${page + 1}`);
            setPage((prevPage) => prevPage + 1); // Увеличиваем номер страницы
            setLoading(false); // Сбрасываем состояние загрузки
            setHasMore(hasMore);
          }
        }
      };

      const container = containerRef.current;

      function ensureDocumentIsScrollable() {
        const isScrollable =
          document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
          document.documentElement.style.setProperty(
            "height",
            "calc(100vh + 1px)",
            "important"
          );
        }
      }
      function preventCollapse() {
        if (window.scrollY === 0) {
          window.scrollTo(0, 1);
        }
      }

      function touchEnd(e: Event) {
        console.log("конец касания");
        //@ts-ignore
        window.Telegram.WebApp.enableVerticalSwipes();
        settingsGl.isTgScrolWorking = true;
      }
      function touchStart(e: Event) {
        //@ts-ignore
        window.Telegram.WebApp.disableVerticalSwipes();
        settingsGl.isTgScrolWorking = false;
        console.log('только начал')
      }
      function touchMove(e: Event) {
        // console.log('В движении')
      }

      function onTouch(e: Event) {
        e.stopPropagation();
      }

      if (container) {
        container.addEventListener("scroll", handleScroll);
        container.addEventListener("touchstart", preventCollapse);

        container.addEventListener("touchstart", touchStart, false);
        container.addEventListener("touchend", touchEnd, false);
        container.addEventListener("touchcancel", preventCollapse, false);
        container.addEventListener("touchmove", touchMove, false);

        window.addEventListener("load", ensureDocumentIsScrollable);
      }

      return () => {
        if (container) {
          container.removeEventListener("scroll", handleScroll);
          // container.removeEventListener("touchstart", preventCollapse);

          container.removeEventListener("touchstart", touchStart, false);
          container.removeEventListener("touchend", touchEnd, false);
          container.removeEventListener("touchcancel", preventCollapse, false);
          container.removeEventListener("touchmove", touchMove, false);

          window.removeEventListener("load", ensureDocumentIsScrollable);
        }
      };
    }, [onScroll, page, loading]);

    return (
      <div
        ref={containerRef}
        style={{
          height: height,
          overflowY: "auto",
          // border: "1px solid #ccc",
          overflowX: "hidden",
        }}
      >
        {/* Здесь могут быть ваши дочерние элементы */}
        <div>
          {children}
          {/* Здесь можно отобразить загруженные данные */}
        </div>
        {loading && <Loader></Loader>} {/* Индикатор загрузки */}
      </div>
    );
  }
);

export default GptScroll;
