import { ListOfEvents } from "./ListOfEvents";
import { BasePage } from "../../common/BasePage";
import { DatePanel } from "./DatePanel";
import { useTelegram } from "../../../hooks/useTelegram";
import { Telegram } from "@tg-app/types";
import { TagsPanel } from "./TagsPanel";
import { AdminService } from "../../../services/AdminService";
import { AdminPanel } from "../../common/AdminPanel";
import { useContext, useEffect } from "react";
import { storeContext } from "../../../store/root-store";
import { FullListOfTags } from "../filter/FullListOfTags";
import { FooterMainButton } from "../../common/FooterMainButton";
import { observer } from "mobx-react-lite";
import { FullListOfDates } from "../filter/FullListOfDates";
import { useNavigate } from "react-router-dom";

// @ts-ignore
// const tg: Telegram.WebApp = window.Telegram.webApp;

/**
 * true - показать filter, false - ленту
 * @param showMenuStatus
 * @param listOfSelectedTags
 * @param listOfSelectedDates
 * @returns
 */
const showMenuFilter = (
  showMenuStatus: boolean,
  listOfSelectedTags: any[],
  listOfSelectedDates: any[]
) => {
  return (
    showMenuStatus || !listOfSelectedDates.length || !listOfSelectedTags.length
  );
};

export const ListPage = observer(() => {
  const { user, tg, onToggleButton } = useTelegram();
  const { settingsGl, filterDatesGl, filterTagGl, adminDateFilterGl } = useContext(storeContext);
  const nav = useNavigate();

  useEffect(() => {
    if (
      !filterTagGl.listOfSelectedTags.length 
    ) {
      nav("/filter");
    }
  }, [
    filterDatesGl.listOfSelecedDates,
    filterTagGl.listOfSelectedTags,
    Array.from(adminDateFilterGl.listOfSelectedDates).length,
  ]);
  return (
    <BasePage>
      {AdminService.getService().isAdmin() && <AdminPanel />}
      {/* <div> */}
      {/* </div> */}
      <ListOfEvents></ListOfEvents>
      <FooterMainButton buttonText="Фильтр" navTo="/filter"></FooterMainButton>
    </BasePage>
  );
});
