import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { storeContext } from "../../../store/root-store";
import { DateHelper } from "../../../helpers/DateHelper";
import { IFilterDate } from "../../../store/FilterDates";

let remainingDays = DateHelper.getRemainingDaysOfWeek();
if (remainingDays.length < 5) {
  const f = remainingDays.length;
  const nextWeekDate = new Date(Date.now() + 60 * 60 * 24 * 1000 * f);
  const nextWeekRemaidingDays = DateHelper.getRemainingDaysOfWeek(nextWeekDate);
  const neededDays = 5 - f;
  remainingDays = [
    ...remainingDays,
    ...nextWeekRemaidingDays.splice(0, neededDays),
  ];
}

export const FullListOfDates = observer(() => {
  const { eventsStore, messagesStoreGl, settingsGl, tagsGl, filterDatesGl } =
    useContext(storeContext);

  const onPickDate = (filterDate: IFilterDate) => {
    filterDatesGl.onPickDate(filterDate);
  };
  return (
    <div>
      <h3>День недели</h3>
      <hr />
      <div className="full-list-of-tags">
        {/* {filterDatesGl.listOfSelecedDates.map(el => el.date)} */}
        {remainingDays.map((el) => {
          return (
            <div
              key={el.dateSec}
              onClick={() => onPickDate(el)}
              className={
                "full-list-of-tags-item " +
                (filterDatesGl.listOfSelecedDates.filter(
                  (t) => t.dateSec === el.dateSec
                ).length
                  ? "full-list-of-tags-item-selected"
                  : "")
              }
            >
              <div className="full-list-of-tags-item-text">
                {el.name +
                  "(" +
                  el.date.split("-").reverse().splice(0, 2).join(".") +
                  ")"}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
