import { makeAutoObservable } from "mobx";
import { filterDatesGl, IFilterDate } from "./FilterDates";
import { AdminService } from "../services/AdminService";
import { eventsStore } from "./Events";
import { filterTagsGl } from "./FilterTags";
import { settingsGl } from "./Settings";

class AdminDateFilter {
  public listOfDates: IFilterDate[] = [];
  public listOfSelectedDates: Set<IFilterDate> = new Set();
  constructor(isAdmin: boolean) {
    makeAutoObservable(this);
    if (isAdmin) {
      this.init();
    }
  }
  public init() {
    const todaySec = Math.floor(Date.now() / 1000);
    const yesterDaySec = Math.floor(Date.now() / 1000) - 60 * 60 * 24;
    const todayDate = new Date()
      .toLocaleDateString("ru-Ru")
      .split(".")
      .reverse()
      .join("-");
    const yeserdayDate = new Date(yesterDaySec * 1000)
      .toLocaleDateString("ru-Ru")
      .split(".")
      .reverse()
      .join("-");
    this.listOfDates.push({
      name: "Вчера",
      dateSec: yesterDaySec,
      date: yeserdayDate,
    });
    this.listOfDates.push({
      name: "Сегодня",
      dateSec: todaySec,
      date: todayDate,
    });
  }
  /**
   * При выборе даты
   */
  public async onPickDate(newDate: IFilterDate) {
    if (this.listOfSelectedDates.has(newDate)) {
      this.listOfSelectedDates.delete(newDate);
    } else {
      this.listOfSelectedDates.add(newDate);
    }
    await eventsStore.getListOfPreviewEvents(
      newDate.dateSec,
      newDate.dateSec,
      filterTagsGl.listOfSelectedTags.map((el) => el.id),
      0,
      1,
      settingsGl.showOnlyUnique,
      settingsGl.currentDayFilter,
      {},
      filterDatesGl.listOfSelecedDates,
      Array.from(adminDateFilterGl.listOfSelectedDates)
    );
  }
}
export const adminDateFilterGl = new AdminDateFilter(
  AdminService.getService().isAdmin()
);
