import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { storeContext } from "../../../store/root-store";
import ITagWithRelated from "../../../store/Tags";
import { Loader } from "../../common/Loader";

export const FullListOfTags = observer(() => {
  const {
    eventsStore,
    messagesStoreGl,
    settingsGl,
    tagsGl,
    filterTagGl: filterSettingsGl,
  } = useContext(storeContext);

  const onSelectCategory = (tag: ITagWithRelated) => {
    filterSettingsGl.onSelectCategory(tag);
  };

  return (
    <div>
      <h3>Категория</h3>
      <hr />
      <div className="full-list-of-tags">
        {tagsGl.tagsIsLoading && <Loader></Loader>}
        {!tagsGl.tagsIsLoading &&
          tagsGl.listOfTags.map((tag) => {
            return (
              <div
                key={tag.id}
                onClick={() => {
                  onSelectCategory(tag);
                }}
                className={
                  "full-list-of-tags-item " +
                  (filterSettingsGl.listOfSelectedTags.filter(
                    (t) => t.id === tag.id
                  ).length
                    ? "full-list-of-tags-item-selected"
                    : "")
                }
                // style={{ border: "1px solid red", marginRight: 0.2 + "rem" }}
              >
                <div className="full-list-of-tags-item-text">
                  {tag.name + "(" + tag.tagCounter + ")"}
                </div>
              </div>
            );
          })}
        {/* {!tagsGl.tagsIsLoading && !tagsGl.listOfTags.length && (
          <strong style={{color:'red'}}>Выберите день</strong>
        )} */}
      </div>
    </div>
  );
});
