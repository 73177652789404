import { IFilterDate } from "../store/FilterDates";

export class DateHelper {
  /**
   * Получить начало и конец недели в секундах
   */
  public static getWeekPeriodSec() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Получаем день недели (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)

    // Вычисляем дату начала недели (понедельник)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(
      today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );

    // Вычисляем дату конца недели (воскресенье)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (7 - (dayOfWeek == 0 ? 7 : dayOfWeek)));

    // Функция для форматирования даты в dd.mm.yyyy
    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
      const year = date.getFullYear();
      return `${year}.${month}.${day}`;
    };
    const dateFromSec = Math.floor(startOfWeek.getTime() / 1000);
    const dateToSec = Math.floor(endOfWeek.getTime() / 1000);

    return {
      dateFromSec: dateFromSec,
      dateToSec: dateToSec,
      dateFromStr: formatDate(endOfWeek),
      dateToStr: formatDate(startOfWeek),
    };
  }
  public formatDate(date: Date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
    const year = date.getFullYear();
    return `${year}.${month}.${day}`;
  }

  /**
   * Получить оставшиеся дни недели
   * @returns
   */
  public static getRemainingDaysOfWeek(currentDate = new Date()): IFilterDate[] {
    const daysOfWeek = [
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
      "Воскресенье",
    ];
    const currentDay = currentDate.getDay(); // Получаем номер дня недели (0 - воскресенье, 1 - понедельник и т.д.)

    // Преобразуем номер дня так, чтобы понедельник был 0
    const adjustedDay = (currentDay + 6) % 7;

    // Создаем массив для хранения оставшихся дней недели
    const remainingDays = [];

    // Заполняем массив оставшимися днями недели
    for (let i = adjustedDay; i < daysOfWeek.length; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + (i - adjustedDay)); // Устанавливаем дату для каждого дня
      remainingDays.push({
        date: date.toLocaleDateString("ru-RU").split(".").reverse().join("-"), // Форматируем дату
        name: daysOfWeek[i],
        dateSec: Math.floor(date.getTime() / 1000),
      });
    }

    return remainingDays;
  }
}
