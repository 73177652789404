import { BasePage } from "../../common/BasePage";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { ListOfEventsForCheck } from "./ListOfEventsForCheck";
import { storeContext } from "../../../store/root-store";
import { ICheckEventStore } from "../../../store/CheckEvents";
import { SearchBox } from "./SearchBox";
import { ScrollMainComponent } from "./ScrollMainComponent";
import GptScroll from "./GptScroll";
import { EventComp } from "../list/EventComp";
import { AdminService } from "../../../services/AdminService";
import { useNavigate } from "react-router-dom";
import { DateButtons } from "../../../enums/DateButtons";
import { Settings } from "../../../store/Settings";

const fakeData = (page: number) => {
  return new Promise<string[]>((resolve) => {
    setTimeout(() => {
      const items = Array.from(
        { length: 20 },
        (_, index) => `Элемент ${(page - 1) * 20 + index + 1}`
      );
      resolve(items);
    }, 1000); // Имитация задержки
  });
};

export const CheckPageWrapper = observer(() => {
  const [data, setData] = useState<string[]>([]);
  const { eventsStore, settingsGl } = useContext(storeContext);
  const nav = useNavigate();

  const getEvents = (currentDayFilter: DateButtons, chunkLength: number) => {
    const timeInterval = Settings.getTimeInterval(currentDayFilter);
    const selectedEvents = settingsGl.selectedListOfEvents;
    const showOnlyUnique = settingsGl.showOnlyUnique;
    return async (currentPage: number = 0) => {
      return await eventsStore.getListOfPreviewEvents(
        timeInterval.startSec,
        timeInterval.finishSec,
        settingsGl.selectedTag?.id ? [settingsGl.selectedTag.id] : [],
        currentPage,
        chunkLength,
        showOnlyUnique,
        settingsGl.currentDayFilter,
        timeInterval
      );
      // return res;
    };
  };

  const loadMoreData = async (page: number) => {
    console.log(`Загрузка данных для страницы ${page}...`);
    const newData = await fakeData(page);
    setData((prevData) => [...prevData, ...newData]);
    console.log(`Данные для страницы ${page} загружены!`);
  };
  const onClick = (id: number) => {
    if (AdminService.getService().isAdmin()) {
      nav("events/" + id);
    }
  };

  useEffect(() => {
    loadMoreData(1);
    getEvents(settingsGl.currentDayFilter, settingsGl.chunkLength)(1);
  }, []);

  return (
    <div>
      <h1>Скролл с асинхронной загрузкой</h1>
      <GptScroll startPage={2} height={300 +'px'} onScroll={getEvents(
            settingsGl.currentDayFilter,
            settingsGl.chunkLength
          )}>
        {/* <div>
          {data.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div> */}

        <div className="row" style={{ marginTop: 1 + "rem" }}>
          {eventsStore.listOfPreviewEvents
            // .filter((el) => el.img_path)
            .map((el, index, ar) => {
              return (
                <div key={el.id} className="col-12 col-sm-12 mt-2">
                  <EventComp
                    onClick={onClick}
                    key={el.id}
                    event={el}
                    // className="col-6 col-sm-6 mt-2"
                    className=""
                  />
                  {/* {index == ar.length - 1 && (
                    <div
                      key={el.id}
                      className="mobile-nav-margin col-12 col-sm-12 mt-2"
                    ></div>
                  )} */}
                </div>
              );
            })}
        </div>
      </GptScroll>
    </div>
  );
});
