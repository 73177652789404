import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { storeContext } from "../../../store/root-store";
import { IFilterDate } from "../../../store/FilterDates";

export const AdminFilter = observer(() => {
  const { adminDateFilterGl } = useContext(storeContext);

  const onPickDate = (filterDate: IFilterDate) => {
    adminDateFilterGl.onPickDate(filterDate);
  };

  return (
    <div>
      <div>
        <h3>По дате добавления</h3>
        <hr />
      </div>
      <div className="full-list-of-tags">
        {adminDateFilterGl.listOfDates.map((el) => {
          return (
            <div
              key={el.dateSec}
              onClick={() => onPickDate(el)}
              className={
                "full-list-of-tags-item " +
                (adminDateFilterGl.listOfSelectedDates.has(el)
                  ? "full-list-of-tags-item-selected"
                  : "")
              }
            >
              <div className="full-list-of-tags-item-text">{el.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
