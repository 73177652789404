import { observer } from "mobx-react-lite";
import { QueryService } from "../../../services/QueryService";
import { useContext, useEffect, useState } from "react";
import { ResponseStatuses } from "../../../enums/ResponseStatuses";
import { storeContext } from "../../../store/root-store";
import { MessageTypes } from "../../../enums/MessageTypes";

interface ITag {
  id: number;
  name: string;
}
interface ITagResponse {
  tags: ITag[];
  status: ResponseStatuses;
}
export const CategoryManagmentPanel = observer(
  ({ eventId }: { eventId: number }) => {
    const { messagesStoreGl } = useContext(storeContext);

    const [listOfTags, setListOfTags] = useState<ITag[]>([]);
    const [currentTag, setCurrentTag] = useState();
    const getListOfTags = async () => {
      const resp = await QueryService.postQuery<ITagResponse>(
        "/api/tags/getTagsForAdmin",
        {
          eventId,
        }
      );
      const listOfTags = resp?.data.data.tags as ITagResponse["tags"];
      setListOfTags([...listOfTags]);
      setCurrentTag(resp?.data.data.currentEventTag);
    };
    const onSave = async (e: any) => {
      e.preventDefault();
      const res = await QueryService.postQuery("/api/admin/changeEventTag", {
        eventId,
        tagId: currentTag,
      });
      if (res) {
       messagesStoreGl.add({
         text: "Успешно изменили категорию",
         type: MessageTypes.ok,
         durationMs: 3000,
       }); 
      }
    };
    const onSelectChage = (e: any) => {
      setCurrentTag(e.target.value);
    };

    useEffect(() => {
      getListOfTags();
    }, []);

    return (
      <div className="mt-2 mb-2">
        <form action="" onSubmit={onSave}>
          <div>
            <select
              required
              onChange={onSelectChage}
              value={currentTag}
              className="form-control"
              name=""
              id=""
              placeholder="Выберите категорию"
            >
              {listOfTags.map((el) => {
                return (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <button className="btn btn-primary">Сохранить</button>
          </div>
        </form>
      </div>
    );
  }
);
