import { makeAutoObservable } from "mobx";
export default interface ITagWithRelated {
  id: number;
  name: string;
  tagCounter: number;
  related: {
    id: number;
    distance: number;
  }[];
}
export class Tags {
  public listOfTags: ITagWithRelated[] = [];
  public tagsIsLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  /**
   * Установить список тэгов
   * @param tags
   */
  public setListOfTags(tags: ITagWithRelated[]) {
    this.listOfTags = tags;
    // tags.forEach((tag: ITagWithRelated) => {
    //   if (!this.listOfTags.map((t) => t.id).includes(tag.id)) {
    //     this.listOfTags.push(tag);
    //   } else {
    //     tagsStore.listOfTags.forEach((storeTag, index) => {
    //       if (storeTag.id == tag.id) {
    //         this.listOfTags[index] = { ...tag };
    //       }
    //     });
    //   }
    // });
  }
}
export const tagsStore = new Tags();
