import { makeAutoObservable } from "mobx";
import { eventsStore } from "./Events";
import { filterTagsGl } from "./FilterTags";
import { settingsGl } from "./Settings";
import { adminDateFilterGl } from "./AdminDateFilter";
export interface IFilterDate {
  name: string;
  date: string;
  dateSec: number;
}
class FilterDates {
  public listOfSelecedDates: IFilterDate[] = [];
  constructor() {
    makeAutoObservable(this);
  }
  public async onPickDate(date: IFilterDate) {
    const hasDate = this.listOfSelecedDates.filter(
      (el) => el.dateSec === date.dateSec
    ).length;
    if (hasDate) {
      this.listOfSelecedDates = this.listOfSelecedDates.filter(
        (el) => el.dateSec !== date.dateSec
      );
    } else {
      this.listOfSelecedDates.push(date);
    }
    await eventsStore.getListOfPreviewEvents(
      date.dateSec,
      date.dateSec,
      filterTagsGl.listOfSelectedTags.map((el) => el.id),
      0,
      1,
      settingsGl.showOnlyUnique,
      settingsGl.currentDayFilter,
      {},
      this.listOfSelecedDates,
      Array.from(adminDateFilterGl.listOfSelectedDates)
    );
  }
}
export const filterDatesGl = new FilterDates();
