import { BasePage } from "../../common/BasePage";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { EventDrawView } from "./EventDrawView";
import { IMoveEvent } from "../../../interfaces/IMoveEvent";


export const InfoPage = () => {
  return (
    <BasePage>
      <h2>О проекте</h2>
      <hr />
      <p>
        Это приложение предназначено для поиска мероприятий в Краснодаре.
        Информация о мероприятихя собирается из телеграмм каналов города. C
        помощью ИИ алгоритмов бот пытается определить приглашение на
        мероприятие, извлечать дату мероприятия, определить тематику. Возможны
        неточности. По вопросам пишите{" "}
        <a href="https://t.me/zahardjagaev"> сюда</a>.
      </p>
      <p>
        Каждый пост в приложение содержит ссылку на источник в телеграмм канале.
        Также каждый пост содержит полную и сокращенную версию описания, по
        умолчанию выводиться сокращенная версия.{" "}
      Подробную и актуальную информацию узнавайте в источнике.
      </p>
    </BasePage>
  );
};
