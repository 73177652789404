import { useContext, useEffect, useRef } from "react";
import { useTelegram } from "../../hooks/useTelegram";
import { observer } from "mobx-react-lite";
import { storeContext } from "../../store/root-store";
import { useNavigate } from "react-router-dom";

export interface IFooterMainButton {
  navTo: string;
  buttonText: string;
  disabled?: boolean;
}
export const FooterMainButton = observer(
  ({ navTo, buttonText, disabled = false }: IFooterMainButton) => {
    const filterButton = useRef<HTMLDivElement>(null);
    const nav = useNavigate();
    const onFilterClick = () => {
      nav(navTo);
    };

    // useEffect(() => {
    //   showTgButton();
    // }, []);
    return (
      <>
        {/* {filterButton.current?.offsetHeight} */}
        <div
          style={{
            height: filterButton.current?.offsetHeight + "px",
            visibility: "hidden",
            border:'1px solid black'
          }}
        ></div>
        <div
          ref={filterButton}
          style={{
            position: "fixed",
            width: 100 + "%",
            fontSize: 1.5 + "rem",
            bottom: 0,
            left: 0,
          }}
          className="d-flex justify-content-center"
        >
          <button
            onClick={(e) => {
              onFilterClick();
            }}
            disabled={disabled}
            style={{ width: 100 + "%" }}
            className={
              "my-time-panel-button " +
              (disabled ? "" : " my-time-panel-button-active")
            }
          >
            {buttonText}
          </button>
        </div>
      </>
    );
  }
);
