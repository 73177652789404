import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="d-flex my-header" style={{ height: 10 + "vh" }}>
      <div className="my-logo">
        <Link to={"/"} className="my-logo" style={{ textDecoration: "none" }}>
          {/* <img
            // width={70 + "px"}
            style={{maxWidth:70 + 'px'}}
            height={"auto"}
            src="/Group 30MyLogo.svg"
            alt=""
          /> */}
          {/* <span>Афиша.</span>
          <br />
          <span>Города</span> */}
          <div className="my-logo-text">
            <span className="my-logo-text-first">Куда</span>
            <span className="my-logo-text-second">Город</span>
          </div>
        </Link>
      </div>
      <div className="my-header-city">Краснодар</div>
    </div>
  );
};
