import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { storeContext } from "../../../store/root-store";

const square = (x: number) => x * x;
const times2 = (x: number) => x * 2;
const sum = (a: number, b: number) => a + b;

const compose = (...listOfFunctions: Function[]) => {
  let fRes = 0;
  return (...listOfArgs: number[]) => {
    listOfFunctions.reverse().forEach((f, index) => {
      if (index === 0) {
        fRes = f(...listOfArgs);
      } else fRes = f(fRes);
    });
    return fRes;
  };
};

export const CheckScrollElement = observer(() => {
  const { settingsGl } = useContext(storeContext);
  const [] = [];
  useEffect(() => {
    console.log("вызывлася CheckScrollElement");
  });

  useEffect(() => {
    console.log("res:" + compose(square, times2, sum)(3, 4));
  }, []);
  return (
    <>
      {/* <div> {"Состояние tgScroll:" + settingsGl.isTgScrolWorking}</div> */}
      {/* <div>{settingsGl.test}</div> */}
    </>
  );
});
