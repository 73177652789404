import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { storeContext } from "../../../store/root-store";
import { EventComp } from "./EventComp";
import { MyCustomScroll } from "../../common/MyCustomScroll";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DateButtons } from "../../../enums/DateButtons";
import { Settings } from "../../../store/Settings";
import { AdminService } from "../../../services/AdminService";
import { EmptyPlaceholder } from "./EmptyPlaceholder";
import GptScroll from "../checkPage/GptScroll";
import { CheckScrollElement } from "./CheckScrollElement";
const EventMemo = React.memo(EventComp);
export const ListOfEvents = observer(() => {
  const {
    eventsStore,
    settingsGl,
    filterTagGl,
    filterDatesGl,
    adminDateFilterGl,
  } = useContext(storeContext);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const contWrapper = useRef<HTMLDivElement | null>(null);

  const nav = useNavigate();

  const getEvents = (currentDayFilter: DateButtons, chunkLength: number) => {
    const timeInterval = Settings.getTimeInterval(currentDayFilter);
    const selectedEvents = settingsGl.selectedListOfEvents;
    const showOnlyUnique = settingsGl.showOnlyUnique;
    return async (currentPage: number = 0) => {
      return await eventsStore.getListOfPreviewEvents(
        timeInterval.startSec,
        timeInterval.finishSec,
        // settingsGl.selectedTag?.id ? [settingsGl.selectedTag.id] : [],
        filterTagGl.listOfSelectedTags.map((el) => el.id),
        currentPage,
        chunkLength,
        showOnlyUnique,
        settingsGl.currentDayFilter,
        timeInterval,
        filterDatesGl.listOfSelecedDates,
        Array.from(adminDateFilterGl.listOfSelectedDates)
      );
      // return res;
    };
  };
  const onClick = (id: number) => {
    if (AdminService.getService().isAdmin()) {
      nav("events/" + id);
    }
  };

  useEffect(() => {
    const container = contWrapper.current;
    eventsStore.listOfPreviewEvents = [];
    // function onTouch(e: Event) {
    //   alert('вызвался touch!')
    // }
    // if (container) {
    //   if (container) {
    //     container.addEventListener("touchstart", onTouch, false);
    //     container.addEventListener("touchend", onTouch, false);
    //     container.addEventListener("touchcancel", onTouch, false);
    //     container.addEventListener("touchmove", onTouch, false);
    //   }
    //   return () => {
    //     container.removeEventListener("touchstart", onTouch, false);
    //     container.removeEventListener("touchend", onTouch, false);
    //     container.removeEventListener("touchcancel", onTouch, false);
    //     container.removeEventListener("touchmove", onTouch, false);
    //   };
    // }
    // getEvents(settingsGl.currentDayFilter, settingsGl.chunkLength)(settingsGl.currentPage);
  }, []);

  return (
    <div className="mt-2">
      {/*<MyScrollComponent2 />*/}
      {AdminService.getService().isAdmin() && (
        <>
          <CheckScrollElement></CheckScrollElement>
        </>
      )}
      <div ref={contWrapper}>
        <GptScroll
          height={82 + "vh"}
          startPage={settingsGl.currentPage}
          key={
            settingsGl.currentDayFilter +
            settingsGl.selectedTag.id +
            settingsGl.showOnlyUnique
          }
          onScroll={getEvents(
            settingsGl.currentDayFilter,
            settingsGl.chunkLength
          )}
          onFirstLoadFinish={(firstLoad) => {
            setFirstLoad(firstLoad);
          }}
        >
          <div className="row" style={{ marginTop: 1 + "rem" }}>
            {eventsStore.listOfPreviewEvents
              // .filter((el) => el.img_path)
              .map((el, index, ar) => {
                return (
                  <div key={el.id} className="col-md-6 col-12 col-sm-12 mt-2">
                    <EventComp
                      onClick={onClick}
                      key={el.id}
                      event={el}
                      // className="col-6 col-sm-6 mt-2"
                      className=""
                    />
                    {/* {index == ar.length - 1 && (
                    <div
                      key={el.id}
                      className="mobile-nav-margin col-12 col-sm-12 mt-2"
                    ></div>
                  )} */}
                  </div>
                );
              })}
            {/* {!settingsGl.selectedTag.id && firstLoad && (
              <EmptyPlaceholder selectedTag={settingsGl.selectedTag} />
            )} */}
          </div>
        </GptScroll>
      </div>
    </div>
  );
});
