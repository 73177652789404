import { makeAutoObservable } from "mobx";
import axios from "axios";
import { isDev, prodUrl, serverUrl } from "../env";
import { messagesStore } from "./Messages";
import { MessageTypes } from "../enums/MessageTypes";
import { ResponseStatuses } from "../enums/ResponseStatuses";
import { QueryService } from "../services/QueryService";
import { EventPreview } from "../types/EventPreview";
import { settingsGl } from "./Settings";
import { tg } from "../hooks/useTelegram";
import { MyEventComponent } from "../types/MyEventComponent";
import ITagWithRelated, { tagsStore } from "./Tags";
import { DateButtons } from "../enums/DateButtons";
import { IFilterDate } from "./FilterDates";

export class Events {
  get listOfPreviewEventsLoaded(): boolean {
    return this._listOfPreviewEventsLoaded;
  }

  set listOfPreviewEventsLoaded(value: boolean) {
    this._listOfPreviewEventsLoaded = value;
  }

  /**
   * Список событий пользователя
   */
  public myListOfEvents: MyEventComponent[] = [];

  public listOfUserEvents: Event[] = [];

  /**
   * Массив для вывода событий на главный экран. Бзе описания.
   */
  public listOfPreviewEvents: EventPreview[] = [];

  /**
   * Переменная чтобы понимать, загрузились ли наши события.
   */
  private _listOfPreviewEventsLoaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Удалить событие
   * @param eventId
   */
  public deleteFromMyListOfEvents(eventId: number) {
    this.myListOfEvents = this.myListOfEvents.filter((el) => {
      return el.id !== eventId;
    });
  }

  /**
   * Получить список событий, которые создал пользователь.
   * @param tgId
   * @param start
   * @param chunkLength
   */
  public async getListOfUserEvents(start: number, chunkLength: number) {
    const pagination = {
      currentPage: start,
      chunkLength,
    };
    const res = await QueryService.postQuery("/api/user/getListOfMyEvents", {
      pagination,
    });
    // if (res?.data.status == ResponseStatuses.ok) {
    //   res.data.forEach((elem: any) => {
    //     this.addEvent(elem);
    //   });
    // }
    if (res?.status === 200 && res.data.status == ResponseStatuses.ok) {
      // this.listOfPreviewEvents = [];

      res?.data.result.forEach((el: MyEventComponent) => {
        this.myListOfEvents.push(el);
      });
    }
    return {
      data: res?.data.result,
      hasMore: res?.data.pagination.hasMore,
    };
  }

  /**
   * Получить список событий для превью
   * @param dateFromSec
   * @param dateToSec
   * @param selectedTagsIds
   * @param start
   * @param chunkLength
   */
  public async getListOfPreviewEvents(
    dateFromSec: number,
    dateToSec: number,
    selectedTagsIds: number[],
    start: number,
    chunkLength: number,
    showOnlyUnique: boolean,
    currentDayFilter: DateButtons,
    additional = {},
    filtredDates: { dateSec: number }[] = [],
    listOfAdminDates: { dateSec: number }[] = []
  ) {
    const pagination = {
      currentPage: start,
      chunkLength,
    };
    tagsStore.tagsIsLoading = true;
    const res = await QueryService.postQuery("/api/events/getListOfEvents", {
      dateFromSec,
      dateToSec,
      selectedTagsIds,
      start,
      pagination,
      showOnlyUnique,
      currentDayFilter,
      additional,
      filtredDates,
      listOfAdminDates,
    });
    if (res?.status === 200 && res.data.status == ResponseStatuses.ok) {
      tagsStore.setListOfTags(res?.data.data.tags);
      res?.data.data.result.forEach((el: EventPreview) => {
        this.listOfPreviewEvents.push(el);
      });
    }
    tagsStore.tagsIsLoading = false;
    return {
      data: res?.data.data.result,
      hasMore: res?.data.data.pagination.hasMore,
    };
  }

  public async onSaveForm() {}

  /**
   * Отправить api запрос на сервер.
   * @param event
   * @param formData
   * @param uri
   */
  async saveEventApi(formData: FormData, uri: string) {
    const response = await axios({
      method: "post",
      url: (isDev ? serverUrl : prodUrl) + uri,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    });
    //Добавляем уведомление
    if (response?.data?.type === ResponseStatuses.ok) {
      messagesStore.add({
        text: response?.data?.message,
        type: MessageTypes.ok,
        durationMs: 3000,
      });
    } else if (response?.data?.type === ResponseStatuses.error) {
      messagesStore.add({
        text: response?.data?.message ?? "Убедитесь в правильности данных",
        type: MessageTypes.error,
        durationMs: 3000,
      });
    }
    return response;
  }

  public async deleteEvent(eventId: number) {
    const res = await QueryService.postQuery("/api/events/deleteEvent", {
      eventId,
    });
  }
}
export class Event implements EventPreview {
  event_date!: string;
  img_path!: string;
  location!: string;
  price!: number;
  id!: number;
  body!: string;
  short_text!: string;
  list_of_dates!: string;

  constructor(event: EventPreview) {
    this.id = event.id;
    this.img_path = event.img_path;
    this.location = event.location;
    this.price = event.price;
    this.body = event.body;
    this.short_text = event.short_text;
    this.list_of_dates = event.list_of_dates;

    makeAutoObservable(this);
  }
}
export const eventsStore = new Events();
